import { ReactElement } from 'react';

import { getAccessToken } from '../libs/accessToken';
import { getLayout as getBaseLayout } from './Layout';

type Props = {
  children: ReactElement | JSX.Element[];
  modal?: boolean;
};
const LayoutFullPage: React.FC<Props> = ({ children }) => <main id="main-content">{children}</main>;

export const getLayout = (page, modal: boolean) =>
  getBaseLayout(<LayoutFullPage modal={modal}>{page}</LayoutFullPage>, modal, getAccessToken() !== undefined);

export default LayoutFullPage;
