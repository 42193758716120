import FourOhFour from '@outdoor-voices/ui/dist/components/FourOhFour';
import Head from 'next/head';

import { FC } from '../types';
import { getLayout } from '../components/LayoutFullPage';
import css from './404.module.scss';

const Page: FC<Record<string, unknown>> = () => (
  <>
    <Head>
      <title key="title">Not Found - Outdoor Voices</title>
    </Head>
    <div className={css.root}>
      <FourOhFour />
    </div>
  </>
);
Page.getLayout = getLayout;
export default Page;
